<div class="unauthorized">
    <div *ngIf="type == 'unauthorized'" class="container">
        <h1>Access Denied</h1>
        <p>You are not authorized to perform the current operation</p>
    </div>
    <div *ngIf="type != 'unauthorized'" class="container">
        <h1>Access Denied</h1>
        <p>You are not authorized to perform the current operation</p>
    </div>
</div>
