import { Injectable,Inject } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OKTA_AUTH} from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { SearchQuery } from '../search-query';
import { SearchItem } from '../search-item';
import { environment } from 'src/environments/environment';
import { PaginationData } from '../pagination-data';
import { SummaryData } from '../summary-data';
@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  token: any = "";
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth ,private httpClient: HttpClient) {  }

  httpPost(url, data){
    this.token = this.oktaAuth.getAccessToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer ' + this.token });
    return this.httpClient.post(url , data, { headers, responseType: 'json' });
  }

  httpGet(url, data?): Observable<any> {
    this.token = this.oktaAuth.getAccessToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer ' + this.token });
    return this.httpClient.get(url, { headers, responseType: 'json' })
  }

  search(data: SearchQuery, paginationData: PaginationData = new PaginationData(0, 10, 0)) {
    let url = environment.authConfig.apiUrl + 'PaidAct/Search?';
    let searchParams = new URLSearchParams();
    searchParams.append('orderby', paginationData.orderBy);
    searchParams.append('sortDirection', paginationData.sortDirection.toUpperCase());
    searchParams.append('count', paginationData.count.toString());
    searchParams.append('skip', paginationData.skip.toString());

    url += searchParams.toString();
    var inputData = {
      ClaimNo: data.claimNumber,
      MedicareId: data.mbi,
      FirstName: data.firstName,
      LastName: data.lastName,
      DOB: data.dateOfBirth,
      SSN: data.ssn
    };
    return this.httpPost(url, inputData);
  }

  checkUser(username){
    let url = environment.authConfig.apiUrl + 'User/CheckUser?';
    let searchParams = new URLSearchParams();
    searchParams.append('username', username);
    url += searchParams.toString();
    return this.httpGet(url);
  }  
  
  isUserInGroup(): Observable<boolean>{
    let url = environment.authConfig.apiUrl + 'User/IsUserInGroup';
    return this.httpGet(url);
  }

  summary(recordId): Observable<SummaryData> {
    let url = environment.authConfig.apiUrl + 'PaidAct/Summary/' + recordId;
    return this.httpGet(url);
  }

}

export interface SearchResult {
  TotalRecords: number,
  PaidActData: SearchItem[]
}
