<div [ngStyle]="{'display':isAuthenticated? 'block':'none'}">
  <app-header></app-header>
  <mat-sidenav-container autosize>
    <mat-sidenav #sidenav [disableClose]="true" class="sidenav" mode="side" opened="true">
        <app-navigator></app-navigator>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>  
  <footer>
    <div class="row">  
      <!-- background-color: #00358E; -->
      <div class="col-sm d-flex justify-content-center" style="background-color: #00358E; color:white;height:50px;padding-top:10px;font-family:roboto;">
      &copy; 2008, 2022 Verisk Analytics, Inc. All rights reserved.
      
      </div>
    </div>
  </footer>
</div>