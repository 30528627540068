export class PaginationData {
  constructor(
    public totalCount = 1,
    // public itemsPerPage = 10,
    public skip = 0,
    public count = 10,
    public orderBy = '',
    public sortDirection = ''
  ) {}

  // public get pageCount() {
  //   return Math.ceil(this.itemCount / this.itemsPerPage);
  // }

  // public get currentPage() {
  //   return (this.skip / this.itemsPerPage) + 1;
  // }

  // public get pageNumList() {
  //   return Array(this.pageCount).fill(0).map((_, i) => i + 1);
  // }
}
