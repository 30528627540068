import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SearchQuery } from '../../search-query';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css']
})
export class SearchFormComponent implements OnInit {

  model: SearchQuery = new SearchQuery();
  public searchForm!: FormGroup;

  @Input() initialState?: SearchQuery;
  @Input() warningMessages: string[] = [];

  @Output() searchEvent: EventEmitter<SearchQuery> = new EventEmitter<SearchQuery>();

  ngOnInit(): void {
    if (this.initialState != undefined) {
      this.model = this.initialState;
    }
    this.clearFormValues();
  }


  search() {
    if(this.searchForm.valid){
      this.model.claimNumber = this.searchForm.get('claimNumber')?.value;
      this.model.mbi = this.searchForm.get('mbi')?.value;
      this.model.dateOfBirth = this.searchForm.get('dateOfBirth')?.value;
      this.model.firstName = this.searchForm.get('firstName')?.value;
      this.model.lastName = this.searchForm.get('lastName')?.value;
      this.model.ssn = this.searchForm.get('ssn')?.value;

      if(this.model.dateOfBirth != "" && this.model.dateOfBirth != null) {
        var date = new Date(this.model.dateOfBirth);
        this.model.dateOfBirth = String(date.getFullYear()) + String((date.getMonth() + 1)).padStart(2, '0') + String(date.getDate()).padStart(2, '0');
      }
      console.log('search submitted');
      this.searchEvent.emit(this.model);
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.searchForm.controls[controlName].hasError(errorName);
  }

  readFormValues() {
    this.model.claimNumber = this.searchForm.get('claimNumber')?.value;
    this.model.mbi = this.searchForm.get('mbi')?.value;
    this.model.dateOfBirth = this.searchForm.get('dateOfBirth')?.value;
    this.model.firstName = this.searchForm.get('firstName')?.value;
    this.model.lastName = this.searchForm.get('lastName')?.value;
    this.model.ssn = this.searchForm.get('ssn')?.value;
  }

  clearFormValues() {
    this.searchForm = new FormGroup({
      claimNumber: new FormControl(''),
      mbi: new FormControl(''),
      ssn: new FormControl('', [Validators.pattern('[0-9]*')]),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      dateOfBirth: new FormControl('')
    });
  }

  clear(){
    this.clearFormValues();
  }

  reset() {
    // would need an event probably to reset pagination and clear the results
    console.log('search reset');
    this.model = new SearchQuery();
  }
}
