<div class="footer-margin">
  <app-search-form [initialState]="searchParams" (searchEvent)="onSearch($event)" />
  <div id="searchResults" class="mt-4" *ngIf="resultVisibility">
    <div *ngIf="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div [ngClass]="{invisible: loading}" class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="ClaimNo" matSortDirection="asc"  (matSortChange)="SortChange($event)" class="mat-elevation-z8 demo-table">
        <ng-container matColumnDef="ClaimNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim Number </th>
          <td mat-cell *matCellDef="let element" class="open"> <span class="dtls" (click)=onEdit(element)> {{element.ClaimNo}} </span></td> 
        </ng-container>
      
        <ng-container matColumnDef="MedicareId">
          <th mat-header-cell *matHeaderCellDef style="cursor: auto;"> Medicare ID </th>
          <td mat-cell *matCellDef="let element"> {{element.MedicareId}} </td>
        </ng-container>
      
        <ng-container matColumnDef="LastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
          <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
        </ng-container>
      
        <ng-container matColumnDef="FirstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
          <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
        </ng-container>        
        
        <ng-container matColumnDef="DateOfEntitlement">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of Entitlement </th>
          <td mat-cell *matCellDef="let element"> {{element.DateOfEntitlement}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator #paginatorPageSize [length]="paginationData.totalCount" [pageSizeOptions]="pageSizes" aria-label="Select page" (page)="onPageChange($event)" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>

