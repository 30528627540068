import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CommonserviceService {
  constructor(public _oktaStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) { }

  async logout() {
    await this.oktaAuth.signOut();
  }
}
