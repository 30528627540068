export const environment = {
  production: true,
  authConfig: {
    "clientId":"0oa1zrcmov5PdPyTF0h8",
    "issuer":"https://sso.int.verisk.com/oauth2/auswqnaynoDVhcUjd0h7",
    "disablehttpcheck":"true",
    "redirectUri":"https://map-navigator-dev.isocptest.com/implicit/callback",
    "apiUrl": "https://mspnav-api-qa.isocptest.com/MSPNavigator/map-api-qa/v1/"
  }
};
