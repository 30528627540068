import { Component,Inject,OnInit } from '@angular/core';
import { OktaAuthStateService ,OKTA_AUTH} from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { filter, map, Observable } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'MSPNavigator.MAP.PaidAct.Web';
  // isAuthenticated!: boolean;
  public isAuthenticated!: Observable<boolean>;
  username?: string;
  constructor( private _oktaStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }
  
  async ngOnInit() {
    this.isAuthenticated = this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );

    // var okta_token = JSON.parse(localStorage.getItem('okta-token-storage') || "");
    // this.username = okta_token.idToken.claims.name;
    // this.isAuthenticated = await this._oktaAuth.isAuthenticated();
    // if (this.isAuthenticated) {
    //   const userClaims = await this._oktaAuth.getUser();
    //   this.username = userClaims.name;
    // }
  }
}
