import { Component } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent {
  constructor(
    private route: ActivatedRoute
  ) {}

  type: "unauthorized" | null | undefined;

  ngOnInit(): void {
    const paramType = this.route.snapshot.paramMap.get("type");
    if (paramType === 'unauthorized' || paramType === null) {
      this.type = paramType;
    }
  }
}
