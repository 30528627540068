import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent} from './components/home/home.component';
import  {environment  } from '../environments/environment';
import { OktaAuth}  from '@okta/okta-auth-js';
import {
  OKTA_CONFIG,
  OktaAuthGuard,
  OktaAuthModule,
  OktaCallbackComponent,
} from '@okta/okta-angular';
import { SearchComponent } from './components/search/search.component';
import { SummaryComponent } from './components/summary/summary.component';
import { AuthGuard } from './auth.guard';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
const oktaAuth = new OktaAuth({

 ...environment.authConfig,
});
const routes: Routes = [
 { path: 'implicit/callback', component: OktaCallbackComponent },
 { path: '', component: SearchComponent, canActivate: [OktaAuthGuard, AuthGuard] },
 { path: 'search', component: SearchComponent, canActivate: [OktaAuthGuard, AuthGuard] },
 { path: 'summary/:recordId', component: SummaryComponent, canActivate: [OktaAuthGuard, AuthGuard] },
 { path: 'unauthorized', component: UnauthorizedComponent, canActivate: [OktaAuthGuard] },
];

@NgModule({
  //imports: [RouterModule.forRoot(routes)],
  imports: [
    OktaAuthModule.forRoot({ oktaAuth }),
    [RouterModule.forRoot(routes, { bindToComponentInputs: true})]
  ],

  /*providers: [
    { provide: OKTA_CONFIG, useValue: env.oidc },
  ],*/
  exports: [RouterModule]
})
export class AppRoutingModule { }
