<div id="claimSummary" class="footer-margin mt-3">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/search">Search</a></li>
      <li class="breadcrumb-item active" aria-current="page">MAP Summary</li>
    </ol>
  </nav>
  <div *ngIf="data != undefined">
    <form #summaryForm="ngForm" class="text-start">
      <div>
        <div class="row">
          <div class="col-sm">
            <div class="container1" >
              <div class="label1" [class.longtext]="setMaxlength == true"> <div>Claim Number</div></div>
              <div class="text1" [class.text_long]="setMaxlength == true">{{data.ClaimNumber}}</div>
            </div>
          </div>
          <div class="col-sm">
            <div class="container1">
              <div class="label1" [class.longtext]="setMaxlength == true">Most Recent Part A Effective Date</div>
              <div class="text1" [class.text_long]="setMaxlength == true">{{data.PartAEffectiveDate}}</div>
            </div>
          </div>
          <div class="col-sm">
            <div class="container1" >
              <div class="label1" [class.longtext]="setMaxlength == true">First Positive Query Response Date</div>
              <div class="text1" [class.text_long]="setMaxlength == true">{{data.FirstPositiveQueryResponse}}</div>
            </div>            
          </div>
          <div class="col-sm">
            <div class="container1">
              <div class="label1" [class.longtext]="setMaxlength == true">Claimant Last Name</div>
              <div class="text1" [class.text_long]="setMaxlength == true">{{data.LastName}}</div>
            </div>
          </div>
          <div class="col-sm">
            <div class="container1">
              <div class="label1" [class.longtext]="setMaxlength == true">Most Recent Part A Term Date</div>
              <div class="text1" [class.text_long]="setMaxlength == true">{{data.PartATerminationDate}}</div>
            </div>
          </div>
          <div class="col-sm">
            <div class="container1">
              <div class="label1" [class.longtext]="setMaxlength == true">Most Recent Query Response Date</div>
              <div class="text1" [class.text_long]="setMaxlength == true">{{data.MostRecentQueryResponse}}</div>
            </div>
          </div>
          <div class="col-sm">
            <div class="container1">
              <div class="label1" [class.longtext]="setMaxlength == true">Claimant First Name</div>
              <div class="text1" [class.text_long]="setMaxlength == true">{{data.FirstName}}</div>
            </div>
          </div>
          <div class="col-sm">
            <div class="container1">
              <div class="label1" [class.longtext]="setMaxlength == true">Most Recent Part B Effective Date</div>
              <div class="text1" [class.text_long]="setMaxlength == true">{{data.PartBEffectiveDate}}</div>
            </div>
          </div>
          <div class="col-sm">
            <div class="container1" >
              <div class="label1" [class.longtext]="setMaxlength == true">Last MAP Update Date</div>
              <div class="text1" [class.text_long]="setMaxlength == true">{{data.LastMapUpdateDate}}</div>
            </div>
          </div>
          <div class="col-sm">
            <div class="container1">
              <div class="label1" [class.longtext]="setMaxlength == true">Medicare Beneficiary Identifier</div>
              <div class="text1" [class.text_long]="setMaxlength == true">{{data.MedicareId}}</div>
            </div>
          </div>
          <div class="col-sm">
            <div class="container1 highwidth">
              <div class="label1" [class.longtext]="setMaxlength == true">Most Recent Part B Term Date</div>
              <div class="text1" [class.text_long]="setMaxlength == true">{{data.PartBTerminationDate}}</div>
            </div>
          </div>
          <div class="col-sm">
            
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="btnExportDiv" *ngIf="data && (data.PartC.length > 0 || data.PartD.length > 0)">
    <button class="vui-btn vui-secondary-btn btnExport" (click)="exportToExcel()">Export</button> 
  </div>
  <br>
  <div *ngIf="data != undefined" class="tablecontainer">
    <!-- Part C Grid -->
    <mat-accordion *ngIf="data.PartC.length > 0">
      <mat-expansion-panel [expanded]="partCOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span style="font-weight: 500;"> Part C </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <table *ngIf="data.PartC.length > 0" mat-table [dataSource]="data.PartC" class="mat-elevation-z8 demo-table partctable" multiTemplateDataRows>
          <ng-container matColumnDef="ActivePlan">
            <th mat-header-cell *matHeaderCellDef> Plan Status </th>
             <td mat-cell *matCellDef="let element"> {{element.PaidData.ActivePlan}} </td> 
          </ng-container>
    
          <ng-container matColumnDef="EnrollmentDate">
            <th mat-header-cell *matHeaderCellDef> Part C Enrollment Date </th>
             <td mat-cell *matCellDef="let element"> {{element.PaidData.EnrollmentDate}} </td> 
          </ng-container>
        
          <ng-container matColumnDef="TerminationDate">
            <th mat-header-cell *matHeaderCellDef> Part C Termination Date </th>
            <td mat-cell *matCellDef="let element"> {{element.PaidData.TerminationDate}} </td>
          </ng-container>
        
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> Part C Name and Address </th>
            <td mat-cell *matCellDef="let element"> 
              <span>{{element.PaidData.ContractName}}</span> <br />
              <span>{{element.PaidData.ContractAddress1}} {{element.PaidData.ContractAddress2}}</span> <br />
              <span> {{element.PaidData.ContractCity}} {{element.PaidData.ContractState}} {{element.PaidData.ContractZip}}</span><br /> </td>
          </ng-container>
        
          <ng-container matColumnDef="ContractNumber">
            <th mat-header-cell *matHeaderCellDef> Part C Contract Number </th>
            <td mat-cell *matCellDef="let element"> {{element.PaidData.ContractNumber}} </td>
          </ng-container>        
          
          <ng-container matColumnDef="PBPNumber">
            <th mat-header-cell *matHeaderCellDef> Part C PBP Number </th>
            <td mat-cell *matCellDef="let element"> {{element.PaidData.PBPNumber}} </td>
          </ng-container>
        
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element" [title]="'Click to ' + (expandedElement_PartC === element ? 'hide' : 'show') + ' Amendments'">
              <button mat-icon-button aria-label="expand row" *ngIf="element.PaidDataHistory != undefined && element.PaidDataHistory.length > 0" (click)="(expandedElement_PartC = expandedElement_PartC === element ? null : element); $event.stopPropagation()">
                <div>
                  <mat-icon *ngIf="expandedElement_PartC === element">remove</mat-icon>
                  <mat-icon *ngIf="expandedElement_PartC !== element">add</mat-icon>
                </div>
              </button>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpandPartC.length">
              <div class="expand-element-detail"
                  [@detailExpandPartC]="element == expandedElement_PartC ? 'expanded' : 'collapsed'">
                <div class="expand-element-diagram">
                  
                  <div *ngIf="element.PaidDataHistory != undefined && element.PaidDataHistory.length > 0" style="margin-left: 80px; padding-top: 10px; padding-bottom: 10px;">
                    <table mat-table [dataSource]="element.PaidDataHistory" class="mat-elevation-z8 ammended" >
                      <ng-container matColumnDef="ActivePlan">
                        <th mat-header-cell *matHeaderCellDef> Plan Status </th>
                         <td mat-cell *matCellDef="let childelement"> {{childelement.ActivePlan}} </td> 
                      </ng-container>

                      <ng-container matColumnDef="EnrollmentDate">
                        <th mat-header-cell *matHeaderCellDef> Part C Enrollment Date </th>
                         <td mat-cell *matCellDef="let childelement"> {{childelement.EnrollmentDate}} </td> 
                      </ng-container>
                    
                      <ng-container matColumnDef="TerminationDate">
                        <th mat-header-cell *matHeaderCellDef> Part C Termination Date </th>
                        <td mat-cell *matCellDef="let childelement"> {{childelement.TerminationDate}} </td>
                      </ng-container>
                    
                      <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef> Part C Name and Address </th>
                        <td mat-cell *matCellDef="let childelement"> 
                          <span>{{childelement.ContractName}}</span> <br />
                          <span>{{childelement.ContractAddress1}} {{childelement.ContractAddress2}}</span> <br />
                          <span> {{childelement.ContractCity}} {{childelement.ContractState}} {{childelement.ContractZip}}</span><br /> </td>
                      </ng-container>
                    
                      <ng-container matColumnDef="ContractNumber">
                        <th mat-header-cell *matHeaderCellDef> Part C Contract Number </th>
                        <td mat-cell *matCellDef="let childelement"> {{childelement.ContractNumber}} </td>
                      </ng-container>        
                      
                      <ng-container matColumnDef="PBPNumber">
                        <th mat-header-cell *matHeaderCellDef> Part C PBP Number </th>
                        <td mat-cell *matCellDef="let childelement"> {{childelement.PBPNumber}} </td>
                      </ng-container>
                    
                      <tr mat-header-row *matHeaderRowDef="displayedColumns_AmmendedPartC"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns_AmmendedPartC;"></tr>
                    </table>
                  </div>

                </div>
              </div>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpandPartC"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpandPartC;"
              class="expand-element-row"
              [class.expand-expanded-row]="expandedElement_PartC === element"
              (click)="expandedElement_PartC = expandedElement_PartC === element ? null : element"
              [ngClass]="{'active-row': element.PaidData.ActivePlan == 'Active'}">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expand-detail-row"></tr>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
    
    <br>
    <!-- Part D Grid -->
    <mat-accordion *ngIf="data.PartD != undefined && data.PartD.length > 0">
      <mat-expansion-panel [expanded]="partDOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span style="font-weight: 500;"> Part D </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <table *ngIf="data.PartD.length > 0" mat-table [dataSource]="data.PartD" class="mat-elevation-z8 demo-table partctable" multiTemplateDataRows>
          <ng-container matColumnDef="ActivePlan">
            <th mat-header-cell *matHeaderCellDef> Plan Status </th>
             <td mat-cell *matCellDef="let element"> {{element.PaidData.ActivePlan}} </td> 
          </ng-container>
    
          <ng-container matColumnDef="EnrollmentDate">
            <th mat-header-cell *matHeaderCellDef> Part D Enrollment Date </th>
             <td mat-cell *matCellDef="let element"> {{element.PaidData.EnrollmentDate}} </td> 
          </ng-container>
        
          <ng-container matColumnDef="TerminationDate">
            <th mat-header-cell *matHeaderCellDef> Part D Termination Date </th>
            <td mat-cell *matCellDef="let element"> {{element.PaidData.TerminationDate}} </td>
          </ng-container>
        
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> Part D Name and Address </th>
            <td mat-cell *matCellDef="let element"> 
              <span>{{element.PaidData.ContractName}}</span> <br />
              <span>{{element.PaidData.ContractAddress1}} {{element.PaidData.ContractAddress2}}</span> <br />
              <span> {{element.PaidData.ContractCity}} {{element.PaidData.ContractState}} {{element.PaidData.ContractZip}}</span><br /> </td>
          </ng-container>
        
          <ng-container matColumnDef="ContractNumber">
            <th mat-header-cell *matHeaderCellDef> Part D Contract Number </th>
            <td mat-cell *matCellDef="let element"> {{element.PaidData.ContractNumber}} </td>
          </ng-container>        
          
          <ng-container matColumnDef="PBPNumber">
            <th mat-header-cell *matHeaderCellDef> Part D PBP Number </th>
            <td mat-cell *matCellDef="let element"> {{element.PaidData.PBPNumber}} </td>
          </ng-container>
        
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element" [title]="'Click to ' + (expandedElement_PartD === element ? 'hide' : 'show') + ' Amendments'">
              <button mat-icon-button aria-label="expand row" *ngIf="element.PaidDataHistory != undefined && element.PaidDataHistory.length > 0" (click)="(expandedElement_PartD = expandedElement_PartD === element ? null : element); $event.stopPropagation()">
                <div>
                  <mat-icon *ngIf="expandedElement_PartD === element">remove</mat-icon>
                  <mat-icon *ngIf="expandedElement_PartD !== element">add</mat-icon>
                </div>
              </button>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpandPartD.length">
              <div class="expand-element-detail"
                  [@detailExpandPartD]="element == expandedElement_PartD ? 'expanded' : 'collapsed'">
                <div class="expand-element-diagram">
                  
                  <div *ngIf="element.PaidDataHistory != undefined && element.PaidDataHistory.length > 0" style="margin-left: 80px; padding-top: 10px; padding-bottom: 10px;">
                    <table mat-table [dataSource]="element.PaidDataHistory" class="mat-elevation-z8 ammended" >
                      <ng-container matColumnDef="ActivePlan">
                        <th mat-header-cell *matHeaderCellDef> Plan Status </th>
                         <td mat-cell *matCellDef="let childelement"> {{childelement.ActivePlan}} </td> 
                      </ng-container>

                      <ng-container matColumnDef="EnrollmentDate">
                        <th mat-header-cell *matHeaderCellDef> Part D Enrollment Date </th>
                         <td mat-cell *matCellDef="let childelement"> {{childelement.EnrollmentDate}} </td> 
                      </ng-container>
                    
                      <ng-container matColumnDef="TerminationDate">
                        <th mat-header-cell *matHeaderCellDef> Part D Termination Date </th>
                        <td mat-cell *matCellDef="let childelement"> {{childelement.TerminationDate}} </td>
                      </ng-container>
                    
                      <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef> Part D Name and Address </th>
                        <td mat-cell *matCellDef="let childelement"> 
                          <span>{{childelement.ContractName}}</span> <br />
                          <span>{{childelement.ContractAddress1}} {{childelement.ContractAddress2}}</span> <br />
                          <span> {{childelement.ContractCity}} {{childelement.ContractState}} {{childelement.ContractZip}}</span><br /> </td>
                      </ng-container>
                    
                      <ng-container matColumnDef="ContractNumber">
                        <th mat-header-cell *matHeaderCellDef> Part D Contract Number </th>
                        <td mat-cell *matCellDef="let childelement"> {{childelement.ContractNumber}} </td>
                      </ng-container>        
                      
                      <ng-container matColumnDef="PBPNumber">
                        <th mat-header-cell *matHeaderCellDef> Part D PBP Number </th>
                        <td mat-cell *matCellDef="let childelement"> {{childelement.PBPNumber}} </td>
                      </ng-container>
                    
                      <tr mat-header-row *matHeaderRowDef="displayedColumns_AmmendedPartD"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns_AmmendedPartD;"></tr>
                    </table>
                  </div>

                </div>
              </div>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpandPartD"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpandPartD;"
              class="expand-element-row"
              [class.expand-expanded-row]="expandedElement_PartD === element"
              (click)="expandedElement_PartD = expandedElement_PartD === element ? null : element"
              [ngClass]="{'active-row': element.PaidData.ActivePlan == 'Active'}">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expand-detail-row"></tr>
        </table>
        <!-- <table *ngIf="data.PartD != undefined && data.PartD.length > 0" mat-table [dataSource]="data.PartD" class="mat-elevation-z8 demo-table">
          <ng-container matColumnDef="ActivePlan">
            <th mat-header-cell *matHeaderCellDef> Plan Status </th>
             <td mat-cell *matCellDef="let element"> {{element.PaidData.ActivePlan}} </td> 
          </ng-container>
    
          <ng-container matColumnDef="EnrollmentDate">
            <th mat-header-cell *matHeaderCellDef> Part D Enrollment Date </th>
             <td mat-cell *matCellDef="let element"> {{element.PaidData.EnrollmentDate}} </td> 
          </ng-container>
        
          <ng-container matColumnDef="TerminationDate">
            <th mat-header-cell *matHeaderCellDef> Part D Termination Date </th>
            <td mat-cell *matCellDef="let element"> {{element.PaidData.TerminationDate}} </td>
          </ng-container>
        
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> Part D Name and Address </th>
            <td mat-cell *matCellDef="let element"> 
              <span>{{element.PaidData.ContractName}}</span> <br />
              <span>{{element.PaidData.ContractAddress1}} {{element.PaidData.ContractAddress2}}</span> <br />
              <span> {{element.PaidData.ContractCity}} {{element.PaidData.ContractState}} {{element.PaidData.ContractZip}}</span><br /> </td>
          </ng-container>
        
          <ng-container matColumnDef="ContractNumber">
            <th mat-header-cell *matHeaderCellDef> Part D Contract Number </th>
            <td mat-cell *matCellDef="let element"> {{element.PaidData.ContractNumber}} </td>
          </ng-container>        
          
          <ng-container matColumnDef="PBPNumber">
            <th mat-header-cell *matHeaderCellDef> Part D PBP Number </th>
            <td mat-cell *matCellDef="let element"> {{element.PaidData.PBPNumber}} </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns_PartC"></tr>
          <tr mat-row [ngClass]="{'active-row': row.ActivePlan == 'Active'}" *matRowDef="let row; columns: displayedColumns_PartC;"></tr>
        </table> -->
      </mat-expansion-panel>
    </mat-accordion>        
  </div>

  <div *ngIf="recordId == undefined">
    Invalid Record ID
  </div>
  <div *ngIf="data === undefined && recordId == undefined">
    <div class="spinner-border"></div>
  </div>
</div>
