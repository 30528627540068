import * as ExcelJS from 'exceljs';
import { SummaryData } from 'src/app/summary-data';

export function exportExcelFile(data?: SummaryData): Promise<ExcelJS.Buffer> {
    const startRow = 6;
    const rowHeight = 55;

    function replaceCommas(input: string): string {
        let i = 0;
        return input.replace(/,/g, (match) => {
          i++;
          return i <= 2 ? '\n' : match;
        });
    }

    function addHeader(data, sheet) {
        sheet.addRow(["Part A Effective Date", data?.PartAEffectiveDate]);
        sheet.addRow(["Part A Termination Date", data?.PartATerminationDate]);
        sheet.addRow(["Part B Effective Date", data?.PartBEffectiveDate]);
        sheet.addRow(["Part B Termination Date", data?.PartBTerminationDate]);   

        sheet.getRow(startRow).values = ['Plan Status', 'Enrollment Date', 'Termination Date', 'Name and Address', 'Contract Number', 'PBP Number'];
    };

    //Changing the color of table 
    function changeHeaderColor(sheet) {
        const header = sheet.getRow(6);
        header.eachCell((cell, colNumber) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' }},
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00629d' }}
        });
    }
    
    function setColumnWidth(sheet) {
        //Setting width for the columns
        sheet.getColumn(1).width = 25; 
        sheet.getColumn(2).width = 25; 
        sheet.getColumn(3).width = 25;
        sheet.getColumn(4).width = 50;
        sheet.getColumn(5).width = 25;
        sheet.getColumn(6).width = 25;

        sheet.getColumn(4).alignment = { wrapText: true };
    }

    const workbook = new ExcelJS.Workbook();
    let sheet = workbook.addWorksheet('Part C');
    addHeader(data, sheet);

    if(data != undefined){
        //Part C records
        if(data.PartC != undefined && data.PartC.length > 0)
        {
            let i = 1;
            data.PartC.forEach(function(item){
                item.PaidData.TerminationDate = item.PaidData.TerminationDate.trim() == '- -' ? "" : item.PaidData.TerminationDate;
                item.PaidData.NameAddress = replaceCommas(item.PaidData.NameAddress);
                sheet.getRow(startRow + i).height = rowHeight;
                sheet.getRow(startRow + i).values = [item.PaidData.ActivePlan, item.PaidData.EnrollmentDate, item.PaidData.TerminationDate, item.PaidData.NameAddress, item.PaidData.ContractNumber, item.PaidData.PBPNumber]
                i = i + 1;

                //Amended Records
                item.PaidDataHistory.forEach(function(amendedItem){
                    amendedItem.TerminationDate = amendedItem.TerminationDate.trim() == '- -' ? "" : amendedItem.TerminationDate;
                    amendedItem.NameAddress = replaceCommas(amendedItem.NameAddress);
                    sheet.getRow(startRow + i).height = rowHeight;
                    sheet.getRow(startRow + i).values = [amendedItem.ActivePlan, amendedItem.EnrollmentDate, amendedItem.TerminationDate, amendedItem.NameAddress, amendedItem.ContractNumber, amendedItem.PBPNumber];
                    i = i + 1;
                });
            });
        }
        changeHeaderColor(sheet);
        setColumnWidth(sheet);

        // Part D records
        let partdsheet = workbook.addWorksheet('Part D');
        addHeader(data, partdsheet);
        if(data.PartD != undefined && data.PartD.length > 0)
        {
            let j = 1;
            data.PartD.forEach(function(item){
                item.PaidData.TerminationDate = item.PaidData.TerminationDate.trim() == '- -' ? "" : item.PaidData.TerminationDate;
                item.PaidData.NameAddress = replaceCommas(item.PaidData.NameAddress);
                partdsheet.getRow(startRow + j).height = rowHeight;
                partdsheet.getRow(startRow + j).values = [item.PaidData.ActivePlan, item.PaidData.EnrollmentDate, item.PaidData.TerminationDate, item.PaidData.NameAddress, item.PaidData.ContractNumber, item.PaidData.PBPNumber];
                j = j + 1;
                // partdsheet.addRow([item.PaidData.ActivePlan, item.PaidData.EnrollmentDate, item.PaidData.TerminationDate, item.PaidData.NameAddress, item.PaidData.ContractNumber, item.PaidData.PBPNumber]);

                //Amended Records
                item.PaidDataHistory.forEach(function(amendedItem){
                    amendedItem.TerminationDate = amendedItem.TerminationDate.trim() == '- -' ? "" : amendedItem.TerminationDate;
                    amendedItem.NameAddress = replaceCommas(amendedItem.NameAddress);
                    partdsheet.getRow(startRow + j).height = rowHeight;
                    partdsheet.getRow(startRow + j).values = [amendedItem.ActivePlan, amendedItem.EnrollmentDate, amendedItem.TerminationDate, amendedItem.NameAddress, amendedItem.ContractNumber, amendedItem.PBPNumber];
                    j = j + 1;
                    // partdsheet.addRow([amendedItem.ActivePlan, amendedItem.EnrollmentDate, amendedItem.TerminationDate, amendedItem.NameAddress, amendedItem.ContractNumber, amendedItem.PBPNumber]);
                });
            });
        }
        changeHeaderColor(partdsheet);
        setColumnWidth(partdsheet);       
    }

    return workbook.xlsx.writeBuffer();
}