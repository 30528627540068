<mat-toolbar>
    <mat-toolbar-row>

        <a class="verisklogocont">
            <!-- <img class="verisklogo" src="../../../../assets/img/verisk-logo-cs.png" alt="verisk logo"> -->
            <img class="verisklogo" src="../../../assets/Logo.svg" alt="verisk logo">
        </a>
        <span class="mat-display-1 appname">
            MAP Navigator
        </span>
        <!-- <span class="version">
            [2.0]
        </span> -->
        <span class="env"> <!--{{env}}-->
           
        </span>
        <!-- <span class="home" (click)="home()"> 
            Home
        </span> -->
        <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
            <ul fxLayout="row" style="height: 10px;" fxLayoutGap="20px">
                <li>
                    <div class="mat-body-1">Welcome: {{username}}</div>
                    <!-- <div class="mat-caption">{{ usertype!=""? buname +" : "+usertype:buname}}</div> -->
                    <!-- <div class="mat-caption">Admin User</div> -->
                </li>

            </ul>
        </div>
        <!-- <span style="margin-right: 45px;cursor:pointer;" matTooltip="Help" routerLink="usermanual">
            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: white;">help_outline</mat-icon>
        </span> -->
        <span style="margin-right: 20pt;cursor:pointer;" matTooltip="logout" (click)="logout()" *ngIf="(_oktaStateService.authState$ | async)?.isAuthenticated">
            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: white;">logout</mat-icon>
        </span>
    </mat-toolbar-row>
</mat-toolbar>