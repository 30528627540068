<mat-nav-list>
    <a mat-list-item (click)="isExpanded = !isExpanded">
      <button [disableRipple]="true" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
    </a>
    <div style="width: 100%;">
      <a mat-list-item class="menu" routerLink="/search" routerLinkActive="active-list-item">
        <div style="float: left;padding-left: 5px;padding-right: 10px;"><mat-icon mat-list-icon>home</mat-icon></div>
        <div style="float: left; padding-top: 2px;" matLine *ngIf="isExpanded">Home</div>
      </a>
    </div>
  </mat-nav-list>
